<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <EventHeader :event="event"/>

      <v-card-title class="headline">{{$t('events.refer-a-friend')}}</v-card-title>

      <v-card-text>{{(race ? race.referral_msg : null) || event.referral_msg}}</v-card-text>

      <v-alert v-if="result && result.status === 'OK'" type="success">
        Thanks! You're message is on it's way. Feel free to invite someone else if you'd like to 😊.
      </v-alert>
      <v-form ref="form" v-model="valid" @submit.prevent="inviteFriend" class="mx-4 mb-4">
          <v-text-field 
              v-model="name" 
              required
              :rules="nameRules"
              :label="$t('events.referral.friend-name')" 
              />
          <v-text-field 
              v-model="email" 
              required
              :rules="emailRules"
              :label="$t('events.referral.friend-email')" 
              />
          <v-textarea
            v-model="message"
            :label="$t('events.referral.friend-message')"
            :rows="4"
            />

          <v-btn color="primary" :loading="$store.getters.isLoading" @click="inviteFriend">
            <v-icon class="mr-2">fa-paper-plane</v-icon>
            {{$t('events.referral.send-invite')}}
          </v-btn>
      </v-form>
      <br/>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventHeader from '@/components/EventHeader.vue';
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      race: null,
      result: null,
      valid: false,
      name: null,
      email: null,
      message: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || this.$t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || this.$t('account.register.email-must-be-valid')
      ],

    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });

  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
      }
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      this.race = this.$route.params.raceId ? this.event.races.find(x => x.id == this.$route.params.raceId) : null;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async inviteFriend() {
      if (!this.valid) {

        return;
      }
      var model = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      this.result = (await eventService.inviteFriend(this.event.id, model)).data;
      this.$helpers.toastResponse(this, this.result, '👍 Success! Your ✉ is on its way!');
      if (this.result.status === 'OK') {
        this.$refs.form.reset();
        this.message = model.message;
      }
    },

  },

  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.refer-a-friend'), disabled: true },
      ];
    },
    
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

